import React, {
  useEffect,
  // useState
} from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
// import SearchIcon from "@mui/icons-material/Search";
// import { useNavigate } from "react-router-dom";
import {
  CardMedia,
  FormControl,
  InputLabel,
  Select,
  // TextField,
} from "@mui/material";
import axios from "axios";
import { apiUrlAced, urlRoot } from "../global";

function Navbar({
  onChangeCampus,
  setEventos,
  __nivel,
  __campus,
  changeModal,
}) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [campus, setCampus] = React.useState("");
  const [nivel, setNivel] = React.useState("");
  const [campusData, setCampusData] = React.useState([]);
  // const [searchValue, setSearchValue] = useState("");
  // const navigate = useNavigate();
  const [isCampusSelectEnabled, setIsCampusSelectEnabled] =
    React.useState(false); // Nuevo estado para controlar habilitación/deshabilitación

  const handleChangeNivel = (event) => {
    localStorage.setItem("nivelStorage", event.target.value);
    const selectedNivel = event.target.value; // Obtener el valor seleccionado del Select
    onChangeCampus(campus, event.target.value);
    setNivel(selectedNivel);
    getCampus(selectedNivel);
    // changeModal(true);
  };
  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const getCampus = (selectedNivel) => {
    axios
      .post(apiUrlAced + "campuses/search", {
        grade: selectedNivel, // Usar el valor seleccionado en la consulta a la API
      })
      .then(function (response) {
        const cambiosDeNombres = {
          Cumbres: "MTY-Cumbres",
          "Santa Catarina": "MTY-Santa Catarina",
          "Eugenio Garza Sada": "MTY-Eugenio Garza Sada",
          "Eugenio Garza Lagüera": "MTY-Eugenio Garza Lagüera",
          "Valle Alto": "MTY-Valle Alto",
        };
        response.data.data.forEach((campus) => {
          if (cambiosDeNombres.hasOwnProperty(campus.name)) {
            campus.name = cambiosDeNombres[campus.name];
          }
        });
        response.data.data.sort((a, b) => {
          if (a.name === "Monterrey") {
            return -1;
          }
          if (b.name === "Monterrey") {
            return 1;
          }

          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        });
        setCampusData(response.data.data);
        setIsCampusSelectEnabled(true); // Habilitar el FormControl
      })
      .catch(function () {
        setIsCampusSelectEnabled(false); // Si ocurre un error, deshabilitar el FormControl
      });
  };
  // const handleSearchClick = () => {
  //   console.log("Valor del TextField:", searchValue);
  // };
  const handleChangeCampus = (campus) => {
    setCampus(campus.target.value);
    // Llamar a la función onChangeCampus del padre con el valor seleccionado del campus
    onChangeCampus(campus.target.value, nivel);
  };
  // const handleInputChange = (event) => {
  //   console.log("enter");
  //   setSearchValue(event.target.value);
  // };
  // const handleInputEnter = (event) => {
  //   if (event.key === "Enter") {
  //     const searchValue = event.target.value;
  //     console.log("enter", searchValue);

  //     // Actualiza el estado con el valor del TextField
  //     // setSearchValue(searchValue);

  //     // Navega a la ruta con el valor de búsqueda
  //     navigate(`/search/${encodeURIComponent(searchValue)}`);
  //   }
  // };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  useEffect(() => {
    if (__nivel) {
      setNivel(__nivel);
      if (__campus) {
        getCampus(__nivel);
        setCampus(capitalizeFirst(__campus));
      }
    }
  }, [__nivel, __campus]); // El segundo argumento es un array vacío, lo que indica que este useEffect se ejecutará solo una vez al montar el componente.

  var ruta = window.location.origin;
  var palabra = "localhost";

  var index = ruta.indexOf(palabra);
  if (index < 0) ruta = ruta + "/" + urlRoot;
  useEffect(() => {
    
    let nivelNavbar='Tec de Monterrey'

    if(__nivel==='prepatec')
      nivelNavbar='PrepaTec'


      if(campus===undefined || campus==='')
      document.title = `Calendario de eventos - Tec de Monterrey`
      else
      document.title = `Eventos de ${campus} | ` +nivelNavbar
      

  }, [campus,__nivel]);

  return (
    <AppBar position="static" sx={{ backgroundColor: "white", color: "black" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
            <a href={"" + ruta + ""} rel="noopener noreferrer">
              <CardMedia
                component="img"
                sx={{ width: "100%", height: "100%" }}
                image={`${ruta}/img/logo-${nivel || "profesional"}.svg`}
                alt="logo"
              />
            </a>
          </Box>
          <Box
            sx={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: { xs: "flex", md: "none" }, // Flex en dispositivos móviles, none en escritorio
            }}
          >
            <Box
              sx={{
                flexGrow: 1,

                display: { xs: "flex", md: "none" },
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyConten: "space-around",
                  }}
                >
                  <Box sx={{ flexGrow: 1, display: { md: "flex" } }}></Box>
                  <Box
                    sx={{
                      display: { xs: "flex", md: "flex", lg: "flex" },
                      justifyConten: "flex-end",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <FormControl sx={{ m: 1, minWidth: 80 }}>
                      {/* Corregir la declaración del Select */}
                      <InputLabel id="demo-simple-select-autowidth-label">
                        Nivel
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        value={nivel} // Usar el valor actual seleccionado
                        onChange={handleChangeNivel} // Pasar la función handleChangeNivel como el evento onChange
                        autoWidth
                        label="Nivel"
                      >
                        <MenuItem value={"profesional"}>Profesional</MenuItem>
                        <MenuItem value={"prepatec"}>PrepaTec</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl
                      sx={{ m: 1, minWidth: 80 }}
                      disabled={!isCampusSelectEnabled}
                    >
                      <InputLabel id="demo-simple-select-autowidth-label">
                        Campus
                      </InputLabel>
                      <Select
                        labelId="campus-autowidth"
                        id="campus-autowidth"
                        value={campus}
                        onChange={handleChangeCampus}
                        autoWidth
                        label="Campus"
                      >
                        <MenuItem value=""></MenuItem>
                        {campusData.map((campus, key) => (
                          <MenuItem key={key} value={campus.name}>
                            {campus.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </Menu>
            </Box>

            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <a href={"" + ruta + ""} rel="noopener noreferrer">
                <CardMedia
                  component="img"
                  sx={{ width: 120 }}
                  image={`${ruta}/img/logo-${nivel || "profesional"}.svg`}
                  alt="logo"
                />
              </a>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              justifyConten: "space-around",
              display: { xs: "none", md: "flex" }, // Flex en dispositivos móviles, none en escritorio
            }}
          >
            <Box
              sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
            ></Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex", lg: "flex" },
                justifyConten: "flex-end",
                alignItems: "center",
              }}
            >
              {/* <div
                className="col-6"
                style={{ display: "flex", alignItems: "center" }}
              >
                <TextField
                  style={{ height: "100%", flexGrow: 1 }}
                  id="outlined-basic"
                  label="Evento"
                  variant="outlined"
                  value={searchValue} // Asigna el valor del estado al TextField
                  onChange={handleInputChange} // Maneja los cambios del TextField
                  onKeyDown={handleInputEnter} // Maneja la tecla Enter
                />

                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleSearchClick}
                >
                  <SearchIcon />
                </div>
              </div> */}
              {/* <div className="col-6"> */}
              <Box sx={{ display: __nivel && __campus ? "block" : "none" }}>
                <FormControl sx={{ m: 1 }}>
                  {/* Corregir la declaración del Select */}
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Nivel
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={nivel} // Usar el valor actual seleccionado
                    onChange={handleChangeNivel} // Pasar la función handleChangeNivel como el evento onChange
                    autoWidth
                    label="Nivel"
                  >
                    <MenuItem value={"profesional"}>Profesional</MenuItem>
                    <MenuItem value={"prepatec"}>PrepaTec</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1 }} disabled={!isCampusSelectEnabled}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Campus
                  </InputLabel>
                  <Select
                    labelId="campus-autowidth"
                    id="campus-autowidth"
                    value={campus}
                    onChange={handleChangeCampus}
                    autoWidth
                    label="Campus"
                  >
                    <MenuItem value=""></MenuItem>
                    {campusData.map((campus, key) => (
                      <MenuItem key={key} value={campus.name}>
                        {campus.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              {/* </div> */}
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export { Navbar };
