import React, { useState, useEffect } from "react";
import "./Evento.css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LinkIcon from "@mui/icons-material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import CardCover from "@mui/joy/CardCover";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import { urlRoot } from "../global";
import { Link } from "react-router-dom";

const DateDisplay = ({ date }) => {
  // Formatear la fecha recibida en el formato deseado
  /*const formattedDate = format(new Date(date), "dd MMMM | HH:mm", {
    locale: es,
  });*/

  const formattedDate = new Date(date);
  // Obtener el día, el mes y el año por separado
  const day = formattedDate.getDate();
  const locale = "es";
  const month = formattedDate.toLocaleString(locale, { month: "long" });
  const year = formattedDate.getFullYear();
  const hours = formattedDate.getHours();
  const minutes = formattedDate.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";

  // Convierte la hora a formato de 12 horas
  const hour12 = hours % 12 || 12;
  let hourShow = hour12;
  if (hourShow < 10) hourShow = "0" + hourShow;

  let minutesShow = minutes;
  if (minutesShow < 10) minutesShow = "0" + minutesShow;

  return (
    <div>
      <p className="fechaEvent">{`${day} de ${month} de ${year} | ${hourShow}:${minutesShow} ${ampm} hora del centro de México.`}</p>
    </div>
  );
};

const DateDisplayBread = ({ date }) => {
  // Convertir la fecha recibida en un objeto Date
  const formattedDate = new Date(date);

  // Obtener el día, el mes y el año por separado
  const day = formattedDate.getDate();
  const locale = "es";
  const month = formattedDate.toLocaleString(locale, { month: "long" });
  const year = formattedDate.getFullYear();

  return (
    <span className="header_date_location_area">{`${day} de ${month} del ${year}`}</span>
  );
};

function Evento({
  EventoNormal,
  Evento,
  Registrado,
  InfoCampus,
  InfoArea,
  btnshow,
  EventoExterno
}) {
 //console.log(Evento);
  //console.log(InfoArea)
  //console.log(InfoArea['nombre']);
  //console.log(InfoArea["color"]);

  const [NameArea, setNameArea] = useState("");
  const [ColorArea, setColorArea] = useState("");
  //console.log(InfoCampus[0]['admissions_email']);


  const metaTag = document.querySelector("meta[name='keywords']");
  const metaDesc = document.querySelector("meta[name='description']");
  const metaTitle= document.querySelector("meta[property='og:title']");
  const metaDescOg= document.querySelector("meta[property='og:description']");
  const metaImg= document.querySelector("meta[property='og:image']");


  let areaNombre=InfoArea["nombre"];
    if (Object.keys(InfoCampus).length === 0)
    console.log("")
  else{
  //console.log("si tiene info")

  if(areaNombre=== undefined)
    areaNombre=''

  if (metaTag)
    metaTag.setAttribute("content", InfoCampus[0].grade+', '+InfoCampus[0].name+', '+Evento[0].event.experience+', '+areaNombre);


  const stripHTML = (htmlString) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
    return tempDiv.textContent || tempDiv.innerText || "";
};

const descriptionText = stripHTML(Evento[0].event.description_text);
const titleText = stripHTML(InfoCampus[0].name);
const imagenEvento = stripHTML(Evento[0].event.imagen);

var texto="PrepaTec - Eventos de "
if(InfoCampus[0].grade==="profesional")
texto="Tecnológico de Monterrey - Eventos de "


  if (metaDesc)
    metaDesc.setAttribute("content",descriptionText);

  if(metaTitle)
    metaTitle.setAttribute("content",texto+titleText);

  if(metaDescOg)
    metaDescOg.setAttribute("content",descriptionText);

  if(metaImg)
    metaImg.setAttribute("content","https://calendario.tec.mx/img/eventos/"+imagenEvento);
  }



  useEffect(() => {
    if (InfoArea["id"]) {
      setNameArea(InfoArea["nombre"] + " /"); // Actualizar el estado con el nuevo valor
      setColorArea(InfoArea["color"]); //FIXME: CAMBIAR PARA PREPA
    }
  }, [InfoArea]);

  var ruta = window.location.origin;
  var palabra = "localhost";
  var index = ruta.indexOf(palabra);
  if (index < 0) ruta = ruta + "/" + urlRoot;

  //const keymap = "";

  return (
    <Box sx={{ flexGrow: 1, paddingLeft: "8%", paddingRight: "8%" }}>
      <Grid container spacing={2}>
        {Evento.map((eventos) => (
          <>
            {/*Datos de breadcumb*/}
            <Grid item xs={12}>
              <div className="info-container">
                <p className="header_date_location_area">
                  {InfoCampus.map((informacionC) => (
                    <p key={informacionC.id}>
                      <Link
                        to={`/busqueda?nivel=${informacionC.grade}`}
                        style={{
                          textTransform: "capitalize",
                          textDecoration: "none",
                          color: "inherit",
                        }}
                      >
                        {informacionC.grade}
                      </Link>
                      {" / "}
                      <Link
                        to={`/busqueda?nivel=${informacionC.grade}&campus=${informacionC.name}`}
                        style={{
                          textTransform: "capitalize",
                          textDecoration: "none",
                          color: "inherit",
                        }}
                      >
                        {informacionC.name === "Varios Campus"
                          ? ""
                          : informacionC.name + " /"}{" "}
                      </Link>
                      <Link
                        to={`/busqueda?nivel=${informacionC.grade}&campus=${informacionC.name}&fecha=${eventos.event.fecha_evento}`}
                        style={{
                          textTransform: "capitalize",
                          textDecoration: "none",
                          color: "inherit",
                        }}
                      >
                        {NameArea}{" "}
                        <DateDisplayBread date={eventos.event.fecha_evento} />
                      </Link>
                    </p>
                  ))}
                </p>
              </div>
            </Grid>

            {/*Titulo*/}
            <Grid item xs={12}>
              <h1 className="titleEvent">{eventos.event.title}</h1>
            </Grid>

            {/*Texto y Botón de registro*/}
            <Grid item md={7} lg={7} xs={12}>
              <p
                className="desEvent"
                dangerouslySetInnerHTML={{
                  __html: eventos.event.description_text,
                }}
              />
              <br></br>

              {btnshow && (
                <Grid spacing={2}>
                  <a
                    href={
                      !EventoExterno ? "#btnValidaR" : eventos.event.room_number
                    }
                    target={!EventoExterno ? "" : "_blank"}
                    rel="noreferrer"
                    class="scroll-link"
                  >
                    <button
                      className="titregL"
                      style={{ "--colorBtn": ColorArea ? ColorArea : "#000" }}
                    >
                      Regístrate
                    </button>
                  </a>
                </Grid>
              )}

              <Grid
                style={{
                  paddingTop: "5%",
                  marginTop:
                    eventos.event.description_text.length <= 66
                      ? "34%"
                      : eventos.event.description_text.length > 60 &&
                        eventos.event.description_text.length < 70
                      ? "14%"
                      : eventos.event.description_text.length > 70 &&
                        eventos.event.description_text.length < 132
                      ? "7%"
                      : eventos.event.description_text.length > 132 &&
                        eventos.event.description_text.length < 198
                      ? "3.5%"
                      : eventos.event.description_text.length > 198 &&
                        eventos.event.description_text.length < 264
                      ? "2%"
                      : eventos.event.description_text.length > 264 &&
                        eventos.event.description_text.length < 330
                      ? "1%"
                      : eventos.event.description_text.length > 330
                      ? ".8%"
                      : "18%",
                }}
              >
                <div className="hour_date_container">
                  <span>
                    <AccessTimeIcon />
                  </span>
                  <DateDisplay date={eventos.event.fecha_evento} />
                </div>


                {!EventoExterno ? (
                  <div>
                {eventos.event.experience === "linea" ? (
                  <p className="fechaEvent">
                    Evento en línea | Regístrate para obtener el link
                  </p>
                ) : null}
                {eventos.event.experience === "presencial" ? (
                  <div className="place_container">
                    <span>
                      <LocationOnIcon />
                    </span>
                    {eventos.event.experience === "linea" ? (
                      <>
                        <a
                          href={eventos.event.room_number}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {eventos.event.room_number}
                        </a>
                      </>
                    ) : (
                      
                      <p className="fechaEvent">{eventos.event.room_number}</p>
                    )}
                  </div>
                ) : null}
                {eventos.event.experience === "hibrido" ? (
                  <div>
                    <p className="fechaEvent">
                      Evento híbrido | Regístrate para obtener el link
                    </p>
                    <div className="place_container">
                      <span>
                        <LocationOnIcon />
                      </span>
                      <p className="fechaEvent">{eventos.event.room_number}</p>
                    </div>
                  </div>
                ) : null}
                {eventos.event.experience === "linea" && Registrado === true ? (
                  <div className="meeting_link_container">
                    <span>
                      <LinkIcon />
                    </span>
                    <p className="fechaEvent">{eventos.event.stream_url}</p>
                  </div>
                ) : null}

                </div>
              ) : null }

              </Grid>
            </Grid>

            {/*Imagen del evento*/}
            <Grid
              item
              md={5}
              lg={5}
              xs={12}
              sx={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                component="ul"
                sx={{ display: "flex", gap: 2, flexWrap: "wrap", p: 0, m: 0 }}
              >
                <Card
                  component="li"
                  md={5}
                  lg={5}
                  sx={{ minHeight: "380px", width: 400 }}
                >
                  <CardCover
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <img
                      src={ruta + "/img/eventos/" + eventos.event.imagen}
                      srcSet={ruta + "/img/eventos/" + eventos.event.imagen}
                      loading="lazy"
                      alt=""
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        display: "block",
                        margin: "0",
                      }}
                    />
                  </CardCover>
                  <CardContent>
                    <Typography
                      level="body-lg"
                      fontWeight="lg"
                      textColor="#fff"
                      mt={{ xs: 12, sm: 18 }}
                    >
                      .
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </>
        ))}
      </Grid>
    </Box>
  );
}

export { Evento };
