const environments = {
  local: "gestor_eventos",
  dev_server: "gestor_eventos",
  prod_server: "",
};
const environment = process.env.REACT_APP_ADM_ENV
const urlRoot = environments[environment];

const protocolo = window.location.protocol; //http
//const serv = window.location.hostname; //localhost
var rootGestor = "calendario.tec.mx/";
var rootApis = "admision.tec.mx";

  if(environment==="dev_server"){
  const hostDev = process.env.REACT_APP_HOST
  rootGestor = hostDev+"/";
  rootApis = hostDev;
}

if(environment==="local"){
  const hostDev = "localhost"
  rootGestor = hostDev+"/";
  rootApis = "10.97.85.129";
}

const apiUrlGestor = protocolo + "//" + rootGestor + urlRoot + "/api/";
const apiUrlAced = protocolo + "//" + rootApis + "/aced/api/directory/";
const apiUrlSaveSpot = "https://admision.tec.mx/savespot/api/";
const SaveSpotGuarda = "prod"; // prod o dev
const apiCoreSave = protocolo + "//" + rootApis + "/coresave/";

export { apiUrlGestor, apiUrlAced, apiCoreSave, apiUrlSaveSpot, urlRoot, SaveSpotGuarda }