import React, { useEffect, useRef, useState } from "react";
import { Campus } from "../../components/campus";
import { Navbar } from "../../components/Navbar";
import { apiUrlGestor, apiUrlAced } from "../../global";
import moment from "moment";
import "moment/locale/es";
import axios from "axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Footer from "../../components/Footer";
import Pagination from "@mui/material/Pagination";
import { useParams } from "react-router-dom";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function callData(
  nivel,
  campusId,
  setEventos,
  setNivel,
  setCampusSoad,
  setCampus,
  setSelectedGrade,
  setImageError,
  setNuevaVariable
) {
  setEventos([]);
  
  let campusSinPrefijo = campusId;
  let campusName = "";
  //let campusPrefijo=""
  if (campusId.includes("MTY-")) {
    campusSinPrefijo = campusId.replace("MTY-", "");
    campusName = campusSinPrefijo;
  } else {
    campusName = campusId;
  }

 /* if(nivel==='prepatec')
    campusPrefijo='prepatec-'*/

  axios
    .post(apiUrlAced + "campuses/search", {
      grade: nivel,
      name: campusName,
    })
    .then(function (response) {
      const campusData = response.data.data;
      
      localStorage.setItem("campusStorageName", campusData[0]["name"]);
      localStorage.setItem("campusStorage", campusData[0]["sf_key"]);
      localStorage.setItem("nivelStorage", nivel);
      setNivel(nivel);
      setSelectedGrade(nivel);
      setCampusSoad(campusData[0]["sf_key"]);
      setCampus(campusData[0]["name"]);
      const img = new Image();
      img.src = `${process.env.PUBLIC_URL}/img/banners/banner-profesional-${campusData[0]["sf_key"]}.webp`;

      img.onerror = () => {
        setImageError(true);
      };

      img.onload = () => {
        setImageError(false);
      };
      let nivel2;
      if (nivel === "prepatec") {
        nivel2 = "03";
      } else {
        nivel2 = "05";
      }
      axios
        .get(
          apiUrlGestor +
            "obtenerEventosHome.php?c=" +
            campusData[0]["sf_key"] +
            "&n=" +
            nivel2+"&cuantos=12"
        )
        .then(function (response) {
          setNuevaVariable(false);
          const eventoData = response.data.events;
          setEventos(eventoData); // Actualizar el estado con los datos recibidos
        })
        .catch(function (error) {
          setNuevaVariable(true);
          console.log(error);
        });
    })
    .catch(function (error) {
      setNuevaVariable(true);
      console.log(error);
    });
}

function CampusView() {
  const [nuevaVariable, setNuevaVariable] = useState(false);
  const campusIdRef = useRef(null); // Crear una referencia inicializada con null
  const eventsPerPage = 10; // Cambia esto según tu preferencia
  const [currentPage, setCurrentPage] = useState(1);
  console.log(typeof setNuevaVariable);
  const handleCampusChangeInParent = (selectedCampus, nivel) => {
    // Actualizar el estado 'eventos' con los nuevos datos del campus seleccionado

    callData(
      nivel,
      selectedCampus,
      setEventos,
      setNivel,
      setCampusSoad,
      setCampus,
      setSelectedGrade,
      setImageError,
      setNuevaVariable
    );
  };

  const [campus, setCampus] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [campusSoad, setCampusSoad] = useState([]);
  const [nivel, setNivel] = useState("");
  const [eventos, setEventos] = useState([]); // Estado del array
  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentEvents = eventos.slice(indexOfFirstEvent, indexOfLastEvent);
  const [imageError, setImageError] = useState(true);
  let { campusId, nivelParam } = useParams();

  nivelParam="profesional"
  const currentUrl = window.location.href;
  const buscarURl = currentUrl.includes('/prepatec');
  if(buscarURl)
    nivelParam="prepatec"
  


  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    // Esperar un breve momento antes de desplazar la página hacia arriba
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 100); // Puedes ajustar el tiempo según lo que funcione mejor para tu caso
  };
  // const filtros = ["LInk1", "Link2", "Link3"];
  useEffect(() => {
    campusIdRef.current = campusId;
  }, [campusId]);
  console.log(nivel);
  useEffect(() => {
    moment.locale("es");
    const campusList = [
      "Cumbres",
      "Santa Catarina",
      "Eugenio Garza Sada",
      "Eugenio Garza Lagüera",
      "Valle Alto",
    ];
    if (campusIdRef.current && campusList.includes(campusIdRef.current)) {
      campusIdRef.current = "MTY-" + campusIdRef.current;
    }

    const campusFromLocalStorage = true;
    setNivel(nivelParam);
    setCampus(campusIdRef.current);
    setSelectedGrade(nivelParam);
    if (campusFromLocalStorage) {
      callData(
        nivelParam,
        campusIdRef.current,
        setEventos,
        setNivel,
        setCampusSoad,
        setCampus,
        setSelectedGrade,
        setImageError,
        setNuevaVariable
      );
    }

    // eslint-disable-next-line no-use-before-define, react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Navbar
        onChangeCampus={handleCampusChangeInParent}
        setEventos={setEventos}
        __nivel={selectedGrade}
        __campus={campus}
      />{" "}
      {/* Pasa la función setEventos al componente Navbar */}
      <Grid
        item
        xs={12}
        sx={{
          mb: 6,
          backgroundImage: `url(${
            imageError
              ? `${process.env.PUBLIC_URL}/img/banners/banner-default.webp`
              : `${process.env.PUBLIC_URL}/img/banners/banner-profesional-${campusSoad}.webp`
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: 140,
          boxShadow: `1px 30px 30px 10px #bfbfbf`,
        }}
      >
        {" "}
      </Grid>
      {/* <h1 > Hola soy campus {campusId} {nivel}</h1> */}
      <Box
        sx={{
          flexGrow: 1,
          mt: 2.5,
          display: nuevaVariable ? "none" : "block", // Muestra el Box si nuevaVariable es true
        }}
      >
        <Grid
          sx={{ display: "flex", justifyContent: "center" }}
          container
          spacing={2}
        >
          <Grid item xs={10}>
            <Grid item xs={12}>
              <Typography component="div" variant="h4" sx={{ mb: 2.5 }}>
                <b>Elige tu evento</b>
              </Typography>
            </Grid>

            {currentEvents.map((evento, key) => (
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                xs={12}
              >
                <Grid item xs={12} sm={8} md={12} lg={12}>
                  <Campus
                    key={key}
                    areaEvent={evento.event.area}
                    nombreEvento={evento.event.title}
                    subtituloEvento={evento.event.description_text}
                    urlImg={evento.event.imagen}
                    fechaEvento={capitalizeFirstLetter(
                      moment(evento.event.fecha_evento).format(
                        "dddd, D [de] MMMM"
                      )
                    )}
                    ubicacionEvento={evento.event.ubicacion}
                    idEvento={evento.event.urlname}
                    tipo_evento={evento.event.tipo_evento}
                  />
                </Grid>
              </Grid>
            ))}
            <Pagination
              count={Math.ceil(eventos.length / eventsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          mt: 2.5,
          display: nuevaVariable ? "block" : "none", // Muestra el Box si nuevaVariable es true
        }}
      >
        <Grid
          sx={{ display: "flex", justifyContent: "center" }}
          container
          spacing={2}
        >
          <Grid item xs={10}>
            <Grid item xs={12}>
              <Typography component="div" variant="h4" sx={{ mb: 2.5 }}>
                <b>Lo sentimos no hay eventos disponibles</b>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <div style={{ marginTop: "5%", height: "1px" }}>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default CampusView;
